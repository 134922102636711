import LinkButton from "components/LinkButton";
import MarkdownContent from "components/MarkdownContent";
import PostCard from "components/PostCard";
import { Fragment } from "react";
import { ContentItem } from "types/content";

interface Props {
  itemClass?: string;
  postsItem?: ContentItem | null | undefined;
}

export default function PostsContent(props: Props) {
  const { itemClass, postsItem } = props;
  if (!postsItem) return <></>;

  function postCard(post: ContentItem) {
    const photoItem =
      post.children && post.children.find((ch) => ch.baseType === "photo");
    const buttonItem =
      post.children && post.children.find((ch) => ch.baseType === "nav");

    return (
      <PostCard
        key={post.id}
        photoItem={photoItem}
        title={post.title as string}
        addedClasses={itemClass}
      >
        <MarkdownContent content={post.content as string} className="pb-4" />
        <div className="flex justify-center mt-6">
          {buttonItem && (
            <LinkButton href={buttonItem.uri || "'"}>
              {buttonItem.label}
            </LinkButton>
          )}
        </div>
      </PostCard>
    );
  }

  return (
    <>
      {postsItem.children?.map((post) => (
        <Fragment key={post.id}>{postCard(post)}</Fragment>
      ))}
    </>
  );
}
