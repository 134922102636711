// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import PostContainer from "components/PostContainer";
import MainContent from "components/layout/MainContent";
import { useAppContext } from "lib/gf-app-context";
import { observer } from "mobx-react-lite";
import { useAutoLogin } from "lib/jwt";
import { usePosts } from "hooks/content";

function Home() {
  const appContext = useAppContext();
  const { groupSlug } = appContext;
  useAutoLogin();

  if (!groupSlug) {
    return <h1 style={{ textAlign: "center", marginTop: "25%" }}>GroupFlow</h1>;
  }

  const { getPostListBySlug: getPost } = usePosts();
  const postsItem = getPost("home-page-posts");

  return (
    <MainContent>
      <PostContainer postsItem={postsItem} />
    </MainContent>
  );
}

export default observer(Home);
